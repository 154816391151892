var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "tablePopup", staticClass: "table-Popup" }, [
    _c(
      "div",
      { ref: "formHeight", staticClass: "content" },
      [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "text" }, [_vm._v("查看")]),
          _c("div", {
            staticClass: "close",
            on: {
              click: function ($event) {
                return _vm.closeFun()
              },
            },
          }),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("订单编号")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(_vm._s(_vm.dataPopup.orderId)),
            ]),
          ]),
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("下单人")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(_vm._s(_vm.dataPopup.passengerPhone)),
            ]),
          ]),
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("联系人手机")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(_vm._s(_vm.dataPopup.orderPhone)),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("线路")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.dataPopup.startStation) +
                  "-" +
                  _vm._s(_vm.dataPopup.endStation) +
                  " "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "individual" },
            [
              _c("div", { staticClass: "label" }, [_vm._v("上车点")]),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: _vm.dataPopup.startAddress,
                    placement: "bottom",
                  },
                },
                [
                  _c("div", { staticClass: "row-val" }, [
                    _vm._v(_vm._s(_vm.dataPopup.startAddress)),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "individual" },
            [
              _c("div", { staticClass: "label" }, [_vm._v("下车点")]),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: _vm.dataPopup.endAddress,
                    placement: "bottom",
                  },
                },
                [
                  _c("div", { staticClass: "row-val" }, [
                    _vm._v(_vm._s(_vm.dataPopup.endAddress)),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("下单时间")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(_vm._s(_vm.dataPopup.createTime)),
            ]),
          ]),
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("班次时间")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.computedShiftIntercityTime(_vm.dataPopup)) +
                  " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("出行时间")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.computedAnticipationRidingTime(_vm.dataPopup)) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("订单状态")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(_vm._s(_vm.SpecialJudgment)),
            ]),
          ]),
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("订单乘客数")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(_vm._s(_vm.dataPopup.passengerCount)),
            ]),
          ]),
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("有效乘客数")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(_vm._s(_vm.dataPopup.validPassengersNum)),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("票价")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(_vm._s(_vm.dataPopup.ticketPricesPer)),
            ]),
          ]),
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("应付金额")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(_vm._s(_vm.dataPopup.payablePrices)),
            ]),
          ]),
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("实付金额")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(_vm._s(_vm.dataPopup.totalPrices)),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("优惠金额")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(_vm._s(_vm.dataPopup.preferentialPrices || 0)),
            ]),
          ]),
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("服务费")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(_vm._s(_vm.dataPopup.servicePricesPer)),
            ]),
          ]),
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("退(改)优惠金额")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.dataPopup.refundTicketPreferentialPrices || 0) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("退票金额")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(_vm._s(_vm.dataPopup.refundFee)),
            ]),
          ]),
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("退票手续费")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(_vm._s(_vm.dataPopup.poundage)),
            ]),
          ]),
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("最后退票时间")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(_vm._s(_vm.dataPopup.refundTime)),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("改签退回金额")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(_vm._s(_vm.dataPopup.changedRefundPrices)),
            ]),
          ]),
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("改签手续费")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(_vm._s(_vm.dataPopup.changedServicePrices)),
            ]),
          ]),
          _c("div", { staticClass: "individual" }, [
            _c("div", { staticClass: "label" }, [_vm._v("最后改签时间")]),
            _c("div", { staticClass: "row-val" }, [
              _vm._v(_vm._s(_vm.dataPopup.changedTime)),
            ]),
          ]),
        ]),
        [50, 80].includes(_vm.dataPopup.orderStatus)
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "individual" }, [
                _c("div", { staticClass: "label" }, [_vm._v("取消操作人")]),
                _c("div", { staticClass: "row-val" }, [
                  _vm._v(_vm._s(_vm.dataPopup.operateUser)),
                ]),
              ]),
              _c("div", { staticClass: "individual" }, [
                _c("div", { staticClass: "label" }, [_vm._v("取消接驾原因")]),
                _c("div", { staticClass: "row-val" }, [
                  _vm._v(_vm._s(_vm.dataPopup.orderAdditionalInformation)),
                ]),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "title" }, [_vm._v("订单明细")]),
        _c("Table", {
          ref: "popup-table",
          attrs: {
            "table-data": _vm.tableData2,
            "title-name": _vm.titleName2,
            "table-height": _vm.tableHeight,
            "col-class-type": true,
          },
        }),
        _c("div", { staticClass: "footer" }, [
          _c(
            "div",
            [
              _c("el-button", { on: { click: _vm.closeFun } }, [
                _vm._v("取消"),
              ]),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }