<template>
  <div ref="tablePopup" class="table-Popup">
    <div ref="formHeight" class="content">
      <div class="header">
        <div class="text">查看</div>
        <div class="close" @click="closeFun()"></div>
      </div>
      <div class="row">
        <div class="individual">
          <div class="label">订单编号</div>
          <div class="row-val">{{ dataPopup.orderId }}</div>
        </div>
        <div class="individual">
          <div class="label">下单人</div>
          <div class="row-val">{{ dataPopup.passengerPhone }}</div>
        </div>
        <div class="individual">
          <div class="label">联系人手机</div>
          <div class="row-val">{{ dataPopup.orderPhone }}</div>
        </div>
      </div>
      <div class="row">
        <div class="individual">
          <div class="label">线路</div>
          <div class="row-val">
            {{ dataPopup.startStation }}-{{ dataPopup.endStation }}
          </div>
        </div>
        <div class="individual">
          <div class="label">上车点</div>
          <el-tooltip
            class="item"
            effect="dark"
            :content="dataPopup.startAddress"
            placement="bottom"
          >
            <div class="row-val">{{ dataPopup.startAddress }}</div>
          </el-tooltip>
        </div>
        <div class="individual">
          <div class="label">下车点</div>
          <el-tooltip
            class="item"
            effect="dark"
            :content="dataPopup.endAddress"
            placement="bottom"
          >
            <div class="row-val">{{ dataPopup.endAddress }}</div>
          </el-tooltip>
        </div>
      </div>
      <div class="row">
        <div class="individual">
          <div class="label">下单时间</div>
          <div class="row-val">{{ dataPopup.createTime }}</div>
        </div>
        <div class="individual">
          <div class="label">班次时间</div>
          <div class="row-val">
            {{ computedShiftIntercityTime(dataPopup) }}
          </div>
        </div>
        <div class="individual">
          <div class="label">出行时间</div>
          <div class="row-val">
            {{ computedAnticipationRidingTime(dataPopup) }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="individual">
          <div class="label">订单状态</div>
          <div class="row-val">{{ SpecialJudgment }}</div>
        </div>
        <div class="individual">
          <div class="label">订单乘客数</div>
          <div class="row-val">{{ dataPopup.passengerCount }}</div>
        </div>
        <div class="individual">
          <div class="label">有效乘客数</div>
          <div class="row-val">{{ dataPopup.validPassengersNum }}</div>
        </div>
      </div>
      <div class="row">
        <div class="individual">
          <div class="label">票价</div>
          <div class="row-val">{{ dataPopup.ticketPricesPer }}</div>
        </div>
        <div class="individual">
          <div class="label">应付金额</div>
          <div class="row-val">{{ dataPopup.payablePrices }}</div>
        </div>
        <div class="individual">
          <div class="label">实付金额</div>
          <div class="row-val">{{ dataPopup.totalPrices }}</div>
        </div>
      </div>
      <div class="row">
        <div class="individual">
          <div class="label">优惠金额</div>
          <div class="row-val">{{ dataPopup.preferentialPrices || 0 }}</div>
        </div>
        <div class="individual">
          <div class="label">服务费</div>
          <div class="row-val">{{ dataPopup.servicePricesPer }}</div>
        </div>
        <div class="individual">
          <div class="label">退(改)优惠金额</div>
          <div class="row-val">
            {{ dataPopup.refundTicketPreferentialPrices || 0 }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="individual">
          <div class="label">退票金额</div>
          <div class="row-val">{{ dataPopup.refundFee }}</div>
        </div>
        <div class="individual">
          <div class="label">退票手续费</div>
          <div class="row-val">{{ dataPopup.poundage }}</div>
        </div>
        <div class="individual">
          <div class="label">最后退票时间</div>
          <div class="row-val">{{ dataPopup.refundTime }}</div>
        </div>
      </div>
      <div class="row">
        <div class="individual">
          <div class="label">改签退回金额</div>
          <div class="row-val">{{ dataPopup.changedRefundPrices }}</div>
        </div>
        <div class="individual">
          <div class="label">改签手续费</div>
          <div class="row-val">{{ dataPopup.changedServicePrices }}</div>
        </div>
        <div class="individual">
          <div class="label">最后改签时间</div>
          <div class="row-val">{{ dataPopup.changedTime }}</div>
        </div>
      </div>
      <div class="row" v-if="[50,80].includes(dataPopup.orderStatus)">
        <div class="individual">
          <div class="label">取消操作人</div>
          <div class="row-val">{{ dataPopup.operateUser }}</div>
        </div>
        <div class="individual">
          <div class="label">取消接驾原因</div>
          <div class="row-val">{{ dataPopup.orderAdditionalInformation }}</div>
        </div>
      </div>
      <div class="title">订单明细</div>
      <Table
        ref="popup-table"
        :table-data="tableData2"
        :title-name="titleName2"
        :table-height="tableHeight"
        :col-class-type="true"
      ></Table>
      <div class="footer">
        <div>
          <el-button @click="closeFun">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableData2: Array,
    titleName2: Array,
    dataPopup: Object,
    orderStatusList: Array,
  },
  data () {
    return {
      tableHeight: 0,
    };
  },
  methods: {
    computedShiftIntercityTime (item) {
      let str = item.departureDate;
      if (item.shiftType === 1) {
        str += ` ${item.startDepartureTime}-${item.endDepartureTime}`
      } else {
        str += ` ${item.startDepartureTime}`
      }
      return str
    },
    computedAnticipationRidingTime (item) {
      let str = "";
      if (item.shiftType === 0) {
        str = item.startDepartureTime;//定班直接取班次时间
      } else {
        str = `${item.startAnticipationRidingTime?.substring(11, 16)}-${item.endAnticipationRidingTime?.substring(11, 16)}`;
      }
      return str
    },
    closeFun () {
      this.$emit("closeFun");
    },
    computeHeight () {
      const wholeHeight = this.$refs.tablePopup.clientHeight;
      const formHeight = this.$refs.formHeight.clientHeight;
      this.tableHeight = wholeHeight - formHeight;
    },
  },
  mounted () {
    console.log(this.dataPopup);
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  computed: {
    SpecialJudgment () {
      return this.orderStatusList.find((t) => {
        return t.value == this.dataPopup.orderStatus;
      }).label;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-Popup {
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: rgba(63, 63, 63, 0.5);
  top: 0;
  left: 0;
  .content {
    position: absolute;
    z-index: 101;
    background: #ffffff;
    border-radius: 10px;
    width: 1000px;
    padding: 0 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 1px;
    .row {
      width: 100%;
      margin: 10px 0;
      display: flex;
      flex-wrap: wrap;
      .individual {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33.3%;
        height: 100%;
        padding: 10px 0;
        .label {
          width: 100px;
          padding-right: 10px;
          text-align: right;
          display: inline-block;
        }
        .row-val {
          padding: 0 10px;
          height: 34px;
          line-height: 34px;
          display: inline-block;
          width: calc(100% - 120px);
          border: 1px solid #d3d3d3;
          border-radius: 4px;
          color: #333333;
          overflow: hidden;
        }
      }
    }
    .title {
      margin-bottom: 15px;
    }
    .header {
      padding: 18px 24px;
      height: 24px;
      width: calc(100% - 48px);
      border-bottom: 1px solid #d7d7d7;
      .text {
        margin-top: 8px;
        display: inline-block;
        font-size: 14px;
      }
      .close {
        float: right;
        margin-top: 10px;
        cursor: pointer;
        width: 12px;
        height: 12px;
        background: url('~@/assets/images/home/close.png') no-repeat;
        background-size: 100%;
        transform: translateY(-50%);
      }
    }
    .footer {
      padding: 10px 0px 18px 24px;
      height: 32px;
      width: calc(100% - 24px);
      border-bottom: none !important;
      border-top: 1px solid #d7d7d7;
      .el-button {
        float: right;
        margin-left: 14px;
      }
      .el-button:first-child {
        background: #336fee;
        color: #ffffff;
      }
    }
  }
}
</style>
